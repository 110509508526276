export default {
    name: 'lni-inline-tabs',

    data() {
        return {
            active: 0,
            focused: null,
        };
    },
    computed: {
        tabSlugs() {
            let tabContents = this.$slots.tab;
            if (this.inline) {
                return tabContents.map(node => this.slugify(node.children[0].text));
            }

            return tabContents.map(node => node.data.attrs['data-route']);
        },
    },
    mounted() {
        let hash = window.location.hash.substring(1);
        if (this.showActiveTab) {
            hash = `/${hash.split('/')[1]}`;
        }
        this.displayTab(hash);

        window.addEventListener('hashchange', this.hashChangeEventHandler);

    },
    methods: {
        toggle(index) {

            const percent = 100;

            let slides = this.$refs[this.id];

            //unset previous
            this.$refs.tabs[this.active].setAttribute('aria-selected', 'false');

            this.active = index;

            this.focused = index;

            //Set current Attribute to true
            this.$refs.tabs[index].setAttribute('aria-selected', 'true');

            slides.style.transform = `translateX(-${index * percent}%)`;

            if (!this.$oneLni.isSpa()) {
                history.replaceState(undefined, undefined, `#${this.tabSlugs[index]}`);
            }
            if (this.$refs.tabs.length) {
                this.$refs.tabs.forEach(t => {
                    t.setAttribute('tabindex', -1);
                });
            }

            this.$refs.tabs[this.focused].setAttribute('tabindex', 0);
            this.$emit('toggle', {
                tab: this.$refs.tabs[index],
                slides,
            });
        },
        focusTrap() {
            // put focus on active slide
            this.$refs.slides[this.active].focus();


            for (let slide in this.$refs.slides) {
                if (Object.prototype.hasOwnProperty.call(this.$refs.slides, slide)) {
                    let allFocusable = this.$refs.slides[slide]
                        .querySelectorAll(`button,
                                            [href],
                                            input,
                                            select,
                                            textarea,
                                            [tabindex]:not([tabindex="-1"])`,
                        );
                    if (parseInt(slide) === this.active) {
                        allFocusable.forEach(t => {
                            t.tabIndex = '0';
                        });
                    } else {
                        allFocusable.forEach(t => {
                            t.tabIndex = '-1';
                        });
                    }
                }
            }
        },
        /*  Handle arrow keydown on top tabs */
        onKeyDown($event) {

            const tab = 9;
            const up = 38;
            const down = 40;
            const left = 37;
            const right = 39;
            const home = 36;
            const end = 35;
            const enter = 13;
            const space = 32;

            const lastTab = this.$refs.tabs.length - 1;
            const nextIndex = this.focused + 1 > lastTab ? 0 : this.focused + 1;
            const prevIndex = this.focused - 1 < 0 ? lastTab : this.focused - 1;

            if ($event.keyCode === tab) {
                return;
            }
            $event.preventDefault();
            this.focused = this.focused || 0;

            switch ($event.keyCode) {
                case up:
                    this.focused = prevIndex;
                    this.$refs.tabs[this.focused].focus();
                    return;
                case down:
                    this.focused = nextIndex;
                    this.$refs.tabs[this.focused].focus();
                    return;
                case left:
                    this.focused = prevIndex;
                    this.$refs.tabs[this.focused].focus();
                    return;
                case right:
                    this.focused = nextIndex;
                    this.$refs.tabs[this.focused].focus();
                    return;
                case end:
                    this.focused = lastTab;
                    this.$refs.tabs[this.focused].focus();
                    return;
                case home:
                    this.focused = 0;
                    this.$refs.tabs[this.focused].focus();
                    return;
                case space:
                case enter:
                    this.$refs.tabs[this.focused].tabIndex = '0';
                    this.$refs.tabs[this.active].tabIndex = '-1';
                    this.navigateOnEnter();
                    break;
                default:
            }
        },
        navigateOnEnter() {
            const tab = this.$refs.tabs[this.focused];
            this.$router.push(tab.dataset.route);
        },
        /*  Handle arrow keydown on top tabs */
        onKeyDownInline($event) {

            const tab = 9;
            const up = 38;
            const down = 40;
            const right = 39;
            const left = 37;
            const home = 36;
            const end = 35;
            const enter = 13;
            const space = 32;

            const lastTab = this.$refs.tabs.length - 1;
            const nextIndex = this.focused + 1 > lastTab ? 0 : this.focused + 1;
            const prevIndex = this.focused - 1 < 0 ? lastTab : this.focused - 1;

            if ($event.keyCode === tab) {
                if ($event.keyCode === tab && $event.shiftKey) {
                    return;
                }
                this.focusTrap();
            } else {
                $event.preventDefault();
                this.focused = this.focused || 0;

                switch ($event.keyCode) {
                    case up:
                        this.focused = prevIndex;
                        this.$refs.tabs[this.focused].focus();
                        return;
                    case down:
                        this.focused = nextIndex;
                        this.$refs.tabs[this.focused].focus();
                        return;
                    case left:
                        this.focused = prevIndex;
                        this.$refs.tabs[this.focused].focus();
                        return;
                    case right:
                        this.focused = nextIndex;
                        this.$refs.tabs[this.focused].focus();
                        return;
                    case end:
                        this.focused = lastTab;
                        this.$refs.tabs[this.focused].focus();
                        return;
                    case home:
                        this.focused = 0;
                        this.$refs.tabs[this.focused].focus();
                        return;
                    case space:
                    case enter:
                        this.$refs.tabs[this.focused].tabIndex = '0';
                        this.$refs.tabs[this.active].tabIndex = '-1';
                        this.toggle(this.focused);
                        break;
                    case tab:
                        this.toggle(this.focused);
                        break;
                    default:

                }
            }
        },
        buildTab(createElement, node, id, index) {
            let selected = index === 0 ? 'true' : 'false';
            let tabToggle = index === 0 ? '0' : '-1';

            const attrs = {
                type: 'button',
                'aria-selected': selected,
                tabindex: tabToggle,
                role: 'tab',
            };

            if (this.inline) {
                attrs['aria-controls'] = id;
            }

            let tabData = {
                attrs: Object.assign( node.data.attrs, attrs),
                class: [
                    node.data.staticClass,
                    'lni-c-inline-tabs__toggle',
                    'lni-u-type--extra-bold',
                    'lni-u-full-width',
                    'lni-u-text--all-caps',
                    'lni-u-self-stretch',
                    {
                        '--active': this.active === index,
                    },
                ],
                on: {
                    click: e => {
                        this.$router.push(e.target.dataset.route);
                    },
                    keydown: e => {
                        this.onKeyDown(e, null);
                    },
                },
                ref: 'tabs',
                refInFor: true,
            };

            return createElement('button', tabData, [node.children]);
        },
        buildInlineTab(createElement, node, id, index) {
            let selected = index === 0 ? 'true' : 'false';
            let tabToggle = index === 0 ? '0' : '-1';

            let tabData = {
                attrs: Object.assign(node.data.attrs, {
                    type: 'button',
                    'aria-controls': id,
                    'aria-selected': selected,
                    tabindex: tabToggle,
                    role: 'tab',
                }),
                class: [
                    node.data.staticClass,
                    'lni-c-inline-tabs__toggle',
                    'lni-u-type--extra-bold',
                    'lni-u-full-width',
                    'lni-u-text--all-caps',
                    'lni-u-self-stretch',
                    {
                        '--active': this.active === index,
                    },
                ],
                on: {
                    click: e => this.toggle(index, e),
                    keydown: e => {
                        this.onKeyDownInline(e, null);
                    },
                },
                ref: 'tabs',
                refInFor: true,
            };

            return createElement('button', tabData, [node.children]);
        },
        buildPanel(createElement, node, id, index, tabLabel) {
            return createElement('section', {
                class: ['lni-c-inline-tabs__panel',
                    'lni-u-full-width',
                    'lni-u-whitespace',
                    'lni-u-inline-block',
                    {
                        '--active': this.active === index,
                    }],
                attrs: {
                    role: 'tabpanel',
                    id,
                    'data-tablabel': tabLabel,
                },
                ref: 'slides',
                refInFor: true,
            }, [node]);
        },
        // https://gist.github.com/hagemann/382adfc57adbd5af078dc93feef01fe1
        // TODO: can these types of functions be made globally available?
        slugify(string) {
            const a = 'àáäâãåăæçèéëêǵḧìíïîḿńǹñòóöôœṕŕßśșțùúüûǘẃẍÿź·/_,:;';
            const b = 'aaaaaaaaceeeeghiiiimnnnoooooprssstuuuuuwxyz------';
            const p = new RegExp(a.split('').join('|'), 'g');

            return string.toString().toLowerCase()
                .replace(/\s+/g, '-') // Replace spaces with -
                .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
                .replace(/&/g, '-and-') // Replace & with 'and'
                /* eslint-disable no-useless-escape */
                .replace(/[^\w\-]+/g, '') // Remove all non-word characters
                .replace(/\-\-+/g, '-') // Replace multiple - with single -
                /* eslint-enable no-useless-escape */
                .replace(/^-+/, '') // Trim - from start of text
                .replace(/-+$/, ''); // Trim - from end of text
        },
        hashChangeEventHandler() {
            this.displayTab(window.location.hash.substring(1));
        },
        displayTab(hash) {
            const percent = 100;

            if (hash && this.tabSlugs.includes(hash)) {
                this.active = this.tabSlugs.indexOf(hash);
                this.$refs[this.id].scrollIntoView({
                    behavior: 'smooth',
                });

                this.$refs[this.id].style.transform = `translateX(-${this.active * percent}%)`;
            }
        },
    },
    render(createElement) {
        let tabContents = this.$slots.tab;
        let panelContents = this.$slots.panel;

        // assume tabs are equal and in same order as panels
        // build array of objects with a tab and a panel
        const groupedItems = tabContents.map((node, index) => {

            //create id from tab label and label from child text
            //assumes the tab content contains only text
            let id = `${this.id}-panel${index}`;
            // Tab label is passed for print views
            let tab;
            let panel;
            if (this.inline) {
                let tabLabel = node.children[0].text;
                tab = this.buildInlineTab(createElement, node, id, index);
                panel = this.buildPanel(createElement, panelContents[index], id, index, tabLabel);
            } else {

                tab = this.buildTab(createElement, node, id, index);
            }

            return {
                tab,
                panel,
            };
        });

        let tabs = groupedItems.map(group => createElement('li', {
            class: ['lni-c-inline-tabs__list-item',
                'lni-u-flex'],
            attrs: {
                role: 'presentation',
            },
        }, [group.tab]));

        let tablist = createElement('ul', {
            class: [
                'lni-c-inline-tabs__tablist',
                'lni-u-flex',
                'lni-u-overflow-x--auto',
                'lni-u-mb3',
                'lni-u-list-reset',
            ],
            attrs: {
                role: 'tablist',
            },
        }, [tabs]);

        let panels = groupedItems.map(group => group.panel);
        //Wrap all the panels in a row
        let panelWrap = createElement('div', {
            class: ['lni-c-inline-tabs__panels',
                'lni-u-nowrap',
                'lni-u-full-width'],
            ref: `${this.id}`,
        }, [panels]);

        let container = createElement('div', {
            class: ['lni-u-full-width',
                'lni-u-overflow-hidden'],
        }, [panelWrap]);

        return createElement('div', {
            ref: 'comp',
            class: 'lni-c-inline-tabs',
        }, [tablist, container]);
    },
    beforeUnmount() {
        window.removeEventListener('hashchange', this.hashChangeEventHandler);
    },
};